'use client'

import NextLink, { LinkProps } from 'next/link'
import { forwardRef, HTMLProps, PropsWithChildren, Ref } from 'react'

import { willOpenLinkInNewTab } from '@/common/components/header/components/autocomplete/helpers/autocomplete.utils'
import { useNavigation } from '@/common/hooks/use-navigation'
import type { ShowSkeletonProps } from '@/providers/skeleton/skeleton-context'

type Props = {
  href: URL | string
  id?: string
  title?: string
  className?: string
  keepUrl?: boolean
  isExternal?: boolean
  skeletonLoader?: ShowSkeletonProps
} & LinkProps &
  Omit<HTMLProps<HTMLAnchorElement>, 'href'>

/**
 * - `keepUrl` -> don't replace the current "origin"
 * - `isExternal` -> redirect to external (use "a" tag instead of nextjs navigation)
 */
export const Link = forwardRef(function Link(
  {
    href,
    keepUrl = false,
    isExternal = false,
    skeletonLoader,
    ...props
  }: Props & PropsWithChildren,
  ref: Ref<HTMLAnchorElement> | undefined,
) {
  const { getUrl, showPageSkeleton } = useNavigation()
  const url = getUrl(href, keepUrl)

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    // To prevent being stuck in "loading" state when opening in a new tab/window
    if (skeletonLoader && !willOpenLinkInNewTab(event)) {
      history.pushState({}, '', url)
      showPageSkeleton({ ...skeletonLoader, url })
    }

    if (props.onClick) {
      props.onClick(event)
    }
  }

  if (isExternal) {
    return <a href={url} {...props} onClick={handleClick} ref={ref} />
  }

  return (
    <NextLink
      data-testid="link"
      prefetch={false}
      href={url}
      {...props}
      onClick={handleClick}
      ref={ref}
    />
  )
})
