'use client'

import type { PropsWithChildren } from 'react'
import { useHydration } from '@/common/hooks/useHydration'

/**
 * No SSR
 */
export function ClientOnlyComponent({ children }: PropsWithChildren) {
  const hydrated = useHydration()

  if (!hydrated) return null

  return children
}
