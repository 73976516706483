'use client'

import { useStoreContext } from '@/providers/store/store-context'
import {
  ShowSkeletonProps,
  useSkeletonContext,
} from '@/providers/skeleton/skeleton-context'
import { getUrl } from '../utils/url-utils'

export type NavigationProps = {
  href?: URL | string
  keepUrl?: boolean
}

export const useNavigation = (): {
  getUrl: (href: URL | string, keepUrl?: boolean) => string
  showPageSkeleton: (skeletonProps: ShowSkeletonProps) => void
} => {
  const {
    storeCode,
    storeConfig: { baseUrl },
  } = useStoreContext()
  const { show } = useSkeletonContext()

  return {
    getUrl: (href, keepUrl) =>
      keepUrl ? href.toString() : getUrl(storeCode, baseUrl, href?.toString()),
    showPageSkeleton: (skeletonProps) => {
      show(skeletonProps)
    },
  }
}
