import React from 'react'

import { getFormattedPrices } from '@/common/utils/price-utils'
import { useStoreContext } from '@/providers'

type Props = {
  productPrice: number | string | undefined
  quantityOrdered?: number | null | undefined
}

const ProductPrice = ({ productPrice, quantityOrdered }: Props) => {
  const {
    storeConfig: { currency, dualCurrency },
    storeCode,
  } = useStoreContext()
  const price = (productPrice ? +productPrice : 0) * (quantityOrdered ?? 1)

  const { mainPrice, localPrice } = getFormattedPrices({
    price: {
      value: price ?? 0,
      currency,
    },
    storeCode,
    dualCurrency,
  })

  return (
    <React.Fragment>
      <span>{mainPrice}</span>

      {dualCurrency?.format && localPrice && (
        <span className="text-grey-300 text-sm font-normal">
          ≈({localPrice})
        </span>
      )}
    </React.Fragment>
  )
}

export default ProductPrice
