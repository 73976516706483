'use client'
import { FC, Fragment, HTMLAttributes, JSX } from 'react'
import { useTranslations } from 'next-intl'

import { selectors } from '@/common/constants/selectors-constants'
import { getFormattedPrices } from '@/common/utils/price-utils'
import { useStoreContext } from '@/providers'
import { CurrencyType } from '@/api'

interface CurrencyProps extends HTMLAttributes<HTMLSpanElement> {
  component?: keyof JSX.IntrinsicElements
  /**
   * @deprecated currency is inferred from storeCode
   */
  currency?: CurrencyType | null
  price?: number | null
  isPriceRange?: boolean
}

const Currency: FC<CurrencyProps> = (props) => {
  const {
    component,
    className,
    role,
    price,
    isPriceRange,
    currency: currencyDeprecated,
  } = props
  const Component = component || 'span'
  const t = useTranslations('ProductBox')
  const {
    storeConfig: { currency: currencyFromStore, dualCurrency },
    storeCode,
  } = useStoreContext()

  const currency = currencyDeprecated ?? currencyFromStore
  const { mainPrice, localPrice } = getFormattedPrices({
    price: {
      value: price ?? 0,
      currency,
    },
    storeCode,
    dualCurrency,
  })

  return (
    <Component role={role} className={className}>
      {price !== undefined ? (
        isPriceRange ? (
          t.rich('price', {
            price: () => (
              <Fragment>
                {mainPrice}
                {dualCurrency?.format && localPrice && (
                  <span className="text-grey-300 text-sm font-normal">
                    ≈({localPrice})
                  </span>
                )}
              </Fragment>
            ),
            from: (chunks) => (
              <span
                className="font-normal pr-1"
                data-test={selectors.HP.bestsellersPrice}
              >
                {chunks}
              </span>
            ),
          })
        ) : (
          <Fragment>
            <span data-test={selectors.HP.bestsellersPrice}>{mainPrice}</span>

            {dualCurrency?.format && localPrice && (
              <span className="text-grey-300 font-normal">≈({localPrice})</span>
            )}
          </Fragment>
        )
      ) : (
        <span />
      )}
    </Component>
  )
}

/**
 * Copy paste of the Currency component, but without the html elements as the parent of this component is an option element
 */
const CurrencyGiftCard: FC<CurrencyProps> = (props) => {
  const { price, isPriceRange, currency: currencyDeprecated } = props
  const t = useTranslations('ProductBox')
  const {
    storeConfig: { currency: currencyFromStore, dualCurrency },
    storeCode,
  } = useStoreContext()

  const currency = currencyDeprecated ?? currencyFromStore
  const { mainPrice, localPrice } = getFormattedPrices({
    price: {
      value: price ?? 0,
      currency,
    },
    storeCode,
    dualCurrency,
  })

  if (!price) {
    return ''
  }

  const showLocalPrice = dualCurrency?.format && localPrice

  if (!isPriceRange) {
    return `${mainPrice}${showLocalPrice ? ` ≈(${localPrice})` : ''}`
  }

  return t.rich('price', {
    price: () => `${mainPrice}${showLocalPrice ? ` ≈(${localPrice})` : ''}`,
    from: (chunks) => chunks,
  })
}

Currency.displayName = 'Currency'
export { Currency, CurrencyGiftCard }
